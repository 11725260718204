export let TaikoJolnir = {
  "name": "Taiko Jolnir L2",
  "chain": "ETH",
  "status": "active",
  "icon": {
    "url": "ipfs://QmcHdmVr5VRUJq13jnM6tgah5Ge7hn3Dm14eY6vwivJ5ui",
    "width": 288,
    "height": 258,
    "format": "png"
  },
  "rpc": [
    "https://rpc.jolnir.taiko.xyz"
  ],
  "faucets": [],
  "nativeCurrency": {
    "name": "Ether",
    "symbol": "ETH",
    "decimals": 18
  },
  "infoURL": "https://taiko.xyz",
  "shortName": "taiko-jolnir-l2",
  "chainId": 167007,
  "networkId": 167007,
  "explorers": [
    {
      "name": "blockscout",
      "url": "https://explorer.jolnir.taiko.xyz",
      "standard": "EIP3091"
    }
  ],
  "testnet": true,
  "slug": "taiko-jolnir-l2"
}

export let BlastL2 = {
  "name": "Blast L2",
  "chain": "BLAST",
  "status": "active",
  "icon": {
    "url": "ipfs://bafybeifc2h3x7jgy4x4nmg2m54ghbvmkfu6oweujambwefzqzew5vujhsi",
    "width": 400,
    "height": 400,
    "format": "png"
  },
  "rpc": [
    "https://blast.din.dev/rpc"
  ],
  "faucets": [],
  "nativeCurrency": {
    "name": "Ether",
    "symbol": "ETH",
    "decimals": 18
  },
  "infoURL": "https://blast.io",
  "shortName": "blast-l2",
  "chainId": 81457,
  "networkId": 81457,
  "explorers": [
    {
      "name": "Blastscan",
      "url": "https://blastscan.io",
      "standard": "EIP3091"
    }
  ],
  "testnet": false,
  "slug": "blast-l2"
}


export let WeaveEVMTestnet = {
  "name": "WeaveEVM Testnet",
  "chain": "WVM",
  "status": "active",
  "icon": {
    "url": "ipfs://QmaRngSbmNJxeAU5dTWtfuwZ5UinezwR9QAu1cJB7SE5mt", 
    "width": 100, 
    "height": 100,
    "format": "png"
  },
  "rpc": [
    "https://testnet-rpc.wvm.dev"
  ],
  "rpcProxy": [
    "https://testnet.wvm.dev"
  ],
  "faucets": [],
  "nativeCurrency": {
    "name": "Test WVM",
    "symbol": "tWVM",
    "decimals": 18
  },
  "infoURL": "https://explorer.wvm.dev",
  "shortName": "wvm-testnet",
  "chainId": 9496,
  "networkId": 9496,
  "explorers": [
    {
      "name": "WVM Explorer",
      "url": "https://explorer.wvm.dev",
      "standard": "EIP3091"
    }
  ],
  "testnet": true,
  "slug": "wvm-testnet"
}


export let ApechainCurtis = {
  "name": "ApeChain Curtis",
  "chain": "APE",
  "status": "active",
  "icon": {
    "url": "ipfs://QmexB2NLdsFD5HytT5WRhgm5r75Qzr9DAzAuSvfkfojZDE",
    "width": 100,
    "height": 100,
    "format": "png"
  },
  "rpc": [
    "https://curtis.rpc.caldera.xyz/http"
  ],
  "rpcProxy": [
    "https://curtis.rpc.caldera.xyz/http"
  ],
  "faucets": [],
  "nativeCurrency": {
    "name": "Ape Token",
    "symbol": "APE",
    "decimals": 18
  },
  "infoURL": "https://curtis.explorer.caldera.xyz",
  "shortName": "ape-chain",
  "chainId": 33111,
  "networkId": 33111,
  "explorers": [
    {
      "name": "ApeChain Explorer",
      "url": "https://curtis.explorer.caldera.xyz",
      "standard": "EIP3091"
    }
  ],
  "testnet": true,
  "slug": "ape-chain"
}
